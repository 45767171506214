// 
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

// Example of a variable override to change theme's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$primary-dark: #6d03ce;
$primary: #7D26CD;
$primary-light: #924fd0;

$headings-font-family: 'DM Sans', sans-serif;