// 
// user.scss
// Use this to write your custom SCSS
//

.filter-brand {
  filter: brightness(0) saturate(100%) invert(22%) sepia(77%) saturate(3007%) hue-rotate(263deg) brightness(82%) contrast(103%);
}

.collapse-toggle::after {
  color: $primary;
}